<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <h4 class="page-header">
          <b-icon icon="account"></b-icon>
          <span v-if="form.id">{{ form.name }}</span>
          <span v-if="!form.id">{{ $ml.get('titles_accounts_new_account') }}</span>
        </h4>
      </div>
    </div>
    <form @submit.prevent="submit" novalidate>
      <b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>

      <div class="columns">
        <div class="column">
          <b-tabs v-model="active_tab" type="is-boxed">
            <b-tab-item value="data" :label="$ml.get('accounts_account')" icon="account">
              <div class="columns">
                <div class="column is-6">
                  <b-field
                      :label="$ml.get('accounts_name') + ' *'"
                      :message="'name' in errors ? errors['name'] : $ml.get('accounts_name_placeholder')"
                      :type="'name' in errors ? 'is-danger' : ''" class="plate">
                    <b-input v-model="form.name" :placeholder="$ml.get('accounts_name_example')" icon="account"></b-input>
                  </b-field>
                </div>
                <div class="column is-3">
                  <b-field
                      :label="$ml.get('accounts_bookmaker') + ' *'"
                      :message="'bookmaker' in errors ? errors['bookmaker'] : $ml.get('accounts_bookmaker_placeholder')"
                      :type="'bookmaker' in errors ? 'is-danger' : ''" class="plate">
                    <b-select v-model="form.bookmaker" icon="tag" expanded :disabled="form.id !== null">
                      <option v-for="option in bookmakers" :value="option" :key="option">
                        {{ option }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column is-3">
                  <b-field
                      :label="$ml.get('accounts_bk_mirror') + ' *'"
                      :message="'bk_mirror' in errors ? errors['bk_mirror'] : ''"
                      :type="'bk_mirror' in errors ? 'is-danger' : ''" class="plate">
                    <b-select v-model="form.bk_mirror" icon="earth" expanded>
                      <option value="">-</option>
                      <option v-for="option in mirrors" :value="option" :key="option">
                        {{ option }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column is-2">
                  <b-field
                      :label="$ml.get('accounts_currency') + ' *'"
                      :message="'currency' in errors ? errors['currency'] : $ml.get('accounts_currency_placeholder')"
                      :type="'currency' in errors ? 'is-danger' : ''" class="plate">
                    <b-select v-model="form.currency" icon="currency-usd-circle" expanded>
                      <option v-for="option in currencies" :value="option" :key="option">
                        {{ option }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
<!--                <div class="column is-4">-->
<!--                  <b-field-->
<!--                      :label="$ml.get('accounts_proxy') + ' *'"-->
<!--                      :message="'proxy_id' in errors ? errors['proxy_id'] : $ml.get('accounts_proxy_placeholder')"-->
<!--                      :type="'proxy_id' in errors ? 'is-danger' : ''" class="plate">-->
<!--                    <b-select v-model="form.proxy_id" icon="earth" expanded>-->
<!--                      <option v-for="option in proxies" :value="option.id" :key="option.id"-->
<!--                              :disabled="!option.available">-->
<!--                        {{ option.name }}-->
<!--                      </option>-->
<!--                    </b-select>-->
<!--                  </b-field>-->
<!--                </div>-->
                <div class="column is-3">
                  <b-field
                      :label="$ml.get('accounts_login') + ' *'"
                      :message="'login' in errors ? errors['login'] : $ml.get('accounts_login_placeholder')"
                      :type="'login' in errors ? 'is-danger' : ''" class="plate">
                    <b-input v-model="form.login" icon="account"></b-input>
                  </b-field>
                </div>
                <div class="column is-3">
                  <b-field
                      :label="$ml.get('accounts_password') + ' *'"
                      :message="'password' in errors ? errors['password'] : $ml.get('accounts_password_placeholder')"
                      :type="'password' in errors ? 'is-danger' : ''" class="plate">
                    <b-input v-model="form.password" icon="lock"></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column is-12">
                  <b-field
                      :label="$ml.get('accounts_comment')"
                      :message="'comment' in errors ? errors['comment'] : $ml.get('accounts_comment_placeholder')"
                      :type="'comment' in errors ? 'is-danger' : ''" class="plate">
                    <b-input size="is-small" type="textarea" v-model="form.comment"></b-input>
                  </b-field>
                </div>
              </div>
            </b-tab-item>
<!--            <b-tab-item value="settings" :label="$ml.get('accounts_settings')" icon="cog">-->
<!--              <div class="columns is-centered" v-if="rooms.length">-->
<!--                <div class="column is-3" style="text-align: right; padding-top: 20px;">-->
<!--                  {{ $ml.get('accounts_timetable_manual_copy') }}-->
<!--                </div>-->
<!--                <div class="column is-5">-->
<!--                  <b-field>-->
<!--                    <b-select v-model="load_settings" icon="account" expanded @change.native="loadSettings()">-->
<!--                      <option v-for="option in rooms" :value="option.id" :key="option.id">-->
<!--                        {{ option.name }}-->
<!--                      </option>-->
<!--                    </b-select>-->
<!--                  </b-field>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="columns">-->
<!--                <div class="column is-6">-->
<!--                  <b-field-->
<!--                      :label="$ml.get('accounts_deposit_balance')"-->
<!--                      :message="'balance_deposit_notification' in errors ? errors['balance_deposit_notification'] : $ml.get('accounts_deposit_balance_placeholder')"-->
<!--                      :type="'balance_deposit_notification' in errors ? 'is-danger' : ''" class="plate">-->
<!--                    <b-input v-model="form.balance_deposit_notification" icon="wallet"></b-input>-->
<!--                  </b-field>-->
<!--                </div>-->
<!--                <div class="column is-6">-->
<!--                  <b-field-->
<!--                      :label="$ml.get('accounts_withdraw_balance')"-->
<!--                      :message="'balance_withdraw_notification' in errors ? errors['balance_withdraw_notification'] : $ml.get('accounts_withdraw_balance_placeholder')"-->
<!--                      :type="'balance_withdraw_notification' in errors ? 'is-danger' : ''" class="plate">-->
<!--                    <b-input v-model="form.balance_withdraw_notification" icon="wallet"></b-input>-->
<!--                  </b-field>-->
<!--                </div>-->
<!--              </div>-->
<!--              <hr>-->
<!--              <div class="columns">-->
<!--                <b-tabs v-model="sport_tab" type="is-boxed" class="tab-content80" vertical expanded :animated="false">-->
<!--                  <b-tab-item v-for="sport in sports" :value="sport">-->
<!--                    <template #header>-->
<!--                      <span :style="form.settings[sport]['active'] ? '' : 'color: #4444'">-->
<!--                      <Sport v-bind:sport="sport"/> {{ $ml.get(sport) }}-->
<!--                      </span>-->
<!--                    </template>-->
<!--                    <h4 style="font-size: 22px; padding-bottom: 20px">-->
<!--                      <Sport v-bind:sport="sport"/> {{ $ml.get(sport) }}-->
<!--                    </h4>-->
<!--                    <div class="columns">-->
<!--                      <div class="column is-3">-->
<!--                        <b-field style="margin-top: 45px;">-->
<!--                          <b-switch v-model="form.settings[sport]['active']">-->
<!--                            {{ $ml.get('accounts_enable_sport') }}-->
<!--                          </b-switch>-->
<!--                        </b-field>-->
<!--                      </div>-->
<!--                      <div class="column is-2">-->
<!--                        <b-field-->
<!--                            :label="$ml.get('accounts_min_coef')"-->
<!--                            :message="'coef_from' in errors ? errors['coef_from'] : ''"-->
<!--                            :type="'coef_from' in errors ? 'is-danger' : ''" class="plate">-->
<!--                          <b-input v-model="form.settings[sport]['coef_from']" icon="cog"></b-input>-->
<!--                        </b-field>-->
<!--                      </div>-->
<!--                      <div class="column is-2">-->
<!--                        <b-field-->
<!--                            :label="$ml.get('accounts_max_coef')"-->
<!--                            :message="'coef_to' in errors ? errors['coef_to'] : ''"-->
<!--                            :type="'coef_to' in errors ? 'is-danger' : ''" class="plate">-->
<!--                          <b-input v-model="form.settings[sport]['coef_to']" icon="cog"></b-input>-->
<!--                        </b-field>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <hr>-->
<!--                    <div class="columns">-->
<!--                      <div class="column is-4">-->
<!--                        <b-field>-->
<!--                          <b-switch v-model="form.settings[sport]['full_time_only']">-->
<!--                            {{ $ml.get('accounts_fulltime_only') }}-->
<!--                          </b-switch>-->
<!--                        </b-field>-->
<!--                      </div>-->
<!--                      <div class="column is-4">-->
<!--                        <b-field>-->
<!--                          <b-switch v-model="form.settings[sport]['not_bet_junior']">-->
<!--                            {{ $ml.get('accounts_bet_junior') }}-->
<!--                          </b-switch>-->
<!--                        </b-field>-->
<!--                      </div>-->
<!--                      <div class="column is-4">-->
<!--                        <b-field>-->
<!--                          <b-switch v-model="form.settings[sport]['not_bet_woman']">-->
<!--                            {{ $ml.get('accounts_bet_woman') }}-->
<!--                          </b-switch>-->
<!--                        </b-field>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <hr>-->
<!--                    <div class="columns is-multiline">-->
<!--                      <div class="column is-one-quarter">-->
<!--                        <ul class="markets-list">-->
<!--                          <li class="has-text-left" style="padding: 5px">-->
<!--                            <div class="market-cont">-->
<!--                              <div class="market-name">{{ $ml.get('TOTAL') }}</div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['TOTAL']['OVER']['active']">-->
<!--                                    {{ $ml.get('TOTAL__OVER') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['TOTAL']['OVER']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['TOTAL']['OVER']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['TOTAL']['UNDER']['active']">-->
<!--                                    {{ $ml.get('TOTAL__UNDER') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['TOTAL']['UNDER']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['TOTAL']['UNDER']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                      <div class="column is-one-quarter">-->
<!--                        <ul class="markets-list">-->
<!--                          <li class="has-text-left" style="padding: 5px">-->
<!--                            <div class="market-cont">-->
<!--                              <div class="market-name">{{ $ml.get('T1_TOTAL') }}</div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['T1_TOTAL']['OVER']['active']">-->
<!--                                    {{ $ml.get('T1_TOTAL__OVER') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['T1_TOTAL']['OVER']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['T1_TOTAL']['OVER']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['T1_TOTAL']['UNDER']['active']">-->
<!--                                    {{ $ml.get('T1_TOTAL__UNDER') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['T1_TOTAL']['UNDER']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['T1_TOTAL']['UNDER']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                      <div class="column is-one-quarter">-->
<!--                        <ul class="markets-list">-->
<!--                          <li class="has-text-left" style="padding: 5px">-->
<!--                            <div class="market-cont">-->
<!--                              <div class="market-name">{{ $ml.get('T2_TOTAL') }}</div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['T2_TOTAL']['OVER']['active']">-->
<!--                                    {{ $ml.get('T2_TOTAL__OVER') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['T2_TOTAL']['OVER']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['T2_TOTAL']['OVER']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['T2_TOTAL']['UNDER']['active']">-->
<!--                                    {{ $ml.get('T2_TOTAL__UNDER') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['T2_TOTAL']['UNDER']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['T2_TOTAL']['UNDER']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                      <div class="column is-one-quarter" v-if="sport === 'FOOTBALL'">-->
<!--                        <ul class="markets-list">-->
<!--                          <li class="has-text-left" style="padding: 5px">-->
<!--                            <div class="market-cont">-->
<!--                              <div class="market-name">{{ $ml.get('CORNER_TOTAL') }}</div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['CORNER_TOTAL']['OVER']['active']">-->
<!--                                    {{ $ml.get('CORNER_TOTAL__OVER') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['CORNER_TOTAL']['OVER']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['CORNER_TOTAL']['OVER']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['CORNER_TOTAL']['UNDER']['active']">-->
<!--                                    {{ $ml.get('CORNER_TOTAL__UNDER') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['CORNER_TOTAL']['UNDER']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['CORNER_TOTAL']['UNDER']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                      <div class="column is-one-quarter">-->
<!--                        <ul class="markets-list">-->
<!--                          <li class="has-text-left" style="padding: 5px">-->
<!--                            <div class="market-cont">-->
<!--                              <div class="market-name">{{ $ml.get('HDP') }}</div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['HDP']['HOME']['active']">-->
<!--                                    {{ $ml.get('HDP__HOME') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['HDP']['HOME']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['HDP']['HOME']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['HDP']['AWAY']['active']">-->
<!--                                    {{ $ml.get('HDP__AWAY') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['HDP']['AWAY']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['HDP']['AWAY']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                      <div class="column is-one-quarter" v-if="sport === 'FOOTBALL'">-->
<!--                        <ul class="markets-list">-->
<!--                          <li class="has-text-left" style="padding: 5px">-->
<!--                            <div class="market-cont">-->
<!--                              <div class="market-name">{{ $ml.get('CORNER_HDP') }}</div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['CORNER_HDP']['HOME']['active']">-->
<!--                                    {{ $ml.get('CORNER_HDP__HOME') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['CORNER_HDP']['HOME']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['CORNER_HDP']['HOME']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['CORNER_HDP']['AWAY']['active']">-->
<!--                                    {{ $ml.get('CORNER_HDP__AWAY') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['CORNER_HDP']['AWAY']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['CORNER_HDP']['AWAY']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                      <div class="column is-one-quarter">-->
<!--                        <ul class="markets-list">-->
<!--                          <li class="has-text-left" style="padding: 5px">-->
<!--                            <div class="market-cont">-->
<!--                              <div class="market-name">{{ $ml.get('EURO_HDP') }}</div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['EURO_HDP']['H1']['active']">-->
<!--                                    {{ $ml.get('EURO_HDP__H1') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['EURO_HDP']['H1']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['EURO_HDP']['H1']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['EURO_HDP']['H2']['active']">-->
<!--                                    {{ $ml.get('EURO_HDP__H2') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['EURO_HDP']['H2']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['EURO_HDP']['H2']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['EURO_HDP']['HX']['active']">-->
<!--                                    {{ $ml.get('EURO_HDP__HX') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['EURO_HDP']['HX']['pivot_from']"></b-input>-->
<!--                                </b-field> - -->
<!--                                <b-field>-->
<!--                                  <b-input type="number" step="0.05" min="-999.00" max="999.00" size="is-small" class="pivot-input" v-model="form.settings[sport]['markets']['EURO_HDP']['HX']['pivot_to']"></b-input>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                      <div class="column is-one-quarter">-->
<!--                        <ul class="markets-list">-->
<!--                          <li class="has-text-left" style="padding: 5px">-->
<!--                            <div class="market-cont">-->
<!--                              <div class="market-name">{{ $ml.get('ONE_TWO') }}</div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['ONE_TWO']['ONE']['active']">-->
<!--                                    {{ $ml.get('ONE_TWO__ONE') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['ONE_TWO']['TWO']['active']">-->
<!--                                    {{ $ml.get('ONE_TWO__TWO') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['ONE_TWO']['ONE_TWO']['active']">-->
<!--                                    {{ $ml.get('ONE_TWO__ONE_TWO') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                              <div class="market-body">-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['ONE_TWO']['DRAW']['active']">-->
<!--                                    {{ $ml.get('ONE_TWO__DRAW') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['ONE_TWO']['ONE_DRAW']['active']">-->
<!--                                    {{ $ml.get('ONE_TWO__ONE_DRAW') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                                <b-field>-->
<!--                                  <b-checkbox v-model="form.settings[sport]['markets']['ONE_TWO']['TWO_DRAW']['active']">-->
<!--                                    {{ $ml.get('ONE_TWO__TWO_DRAW') }}-->
<!--                                  </b-checkbox>-->
<!--                                </b-field>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </b-tab-item>-->
<!--                </b-tabs>-->
<!--              </div>-->
<!--              <hr v-if="form.bookmaker.startsWith('BET365')">-->
<!--              <div class="columns" v-if="form.bookmaker.startsWith('BET365')">-->
<!--                <div class="column is-4">-->
<!--                  <b-field>-->
<!--                    <b-radio v-model="form.leagues_include" name="leagues_include" native-value="0">-->
<!--                      {{ $ml.get('accounts_leagues_include_bet_all') }}-->
<!--                    </b-radio>-->
<!--                  </b-field>-->
<!--                </div>-->
<!--                <div class="column is-4">-->
<!--                  <b-field>-->
<!--                    <b-radio v-model="form.leagues_include" name="leagues_include" native-value="1">-->
<!--                      {{ $ml.get('accounts_leagues_include_include') }}-->
<!--                    </b-radio>-->
<!--                  </b-field>-->
<!--                </div>-->
<!--                <div class="column is-4">-->
<!--                  <b-field>-->
<!--                    <b-radio v-model="form.leagues_include" name="leagues_include" native-value="-1">-->
<!--                      {{ $ml.get('accounts_leagues_include_exclude') }}-->
<!--                    </b-radio>-->
<!--                  </b-field>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="columns" v-if="form.bookmaker.startsWith('BET365')">-->
<!--                <div class="column is-12">-->
<!--                  <b-field grouped group-multiline>-->
<!--                    <div class="control" v-for="league in form.leagues">-->
<!--                      <b-tag type="is-primary" attached :aria-close-label="$ml.get('common_delete')" closable @close="removeLeague(league)">-->
<!--                        {{ league }}-->
<!--                      </b-tag>-->
<!--                    </div>-->
<!--                  </b-field>-->
<!--                  <b-field class="plate">-->
<!--                    <b-input v-model="search_league" icon="magnify"></b-input>-->
<!--                  </b-field>-->
<!--                  <div class="search-results" v-if="leagues_list.length && search_league.length >= 3">-->
<!--                    <ul>-->
<!--                      <li v-for="league in leagues_list" @click="addLeague(league)">-->
<!--                        <span>{{ league }}</span>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-tab-item>-->
            <b-tab-item value="strategies" :label="$ml.get('accounts_strategies')" icon="briefcase">
              <div class="columns is-centered">
                <div class="column">
                  <b-message
                      title=""
                      type="is-info"
                      has-icon
                      aria-close-label="Close message">
                    {{ $ml.get('accounts_strategies_info') }}
                  </b-message>
                </div>
              </div>

              <div v-for="(strategy, ix) in form.strategies" class="box">
                <div class="columns">
                  <div class="column is-5">
                    <b-field :label="$ml.get('accounts_strategy') + ' *'" class="plate">
                      <b-select v-model="strategy.strategy_id" icon="briefcase" expanded @change.native="updateTimetable(0)">
                        <option v-for="option in strategies" :value="option.id" :key="option.id">
                          {{ option.name }} (ROI: {{ option.roi }}%)
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="column is-2">
                    <b-field
                        :label="$ml.get('accounts_stake') + ' *'" class="plate"
                        :message="'strategies' in errors && ix in errors['strategies'] && 'stake1' in errors['strategies'][ix] ? errors['strategies'][ix]['stake1'] : ''"
                        :type="'strategies' in errors && ix in errors['strategies'] && 'stake1' in errors['strategies'][ix] ? 'is-danger' : ''">
                      <b-input v-model="strategy.stake1" icon="currency-usd-circle" :disabled="strategy.dynamic_stake"></b-input>
                    </b-field>
                  </div>
<!--                  <div class="column is-2">-->
<!--                    <b-field :label="$ml.get('accounts_stake') + ' 2'" class="plate"-->
<!--                             :message="'strategies' in errors && ix in errors['strategies'] && 'stake2' in errors['strategies'][ix] ? errors['strategies'][ix]['stake2'] : ''"-->
<!--                             :type="'strategies' in errors && ix in errors['strategies'] && 'stake2' in errors['strategies'][ix] ? 'is-danger' : ''">-->
<!--                      <b-input v-model="strategy.stake2" icon="currency-usd-circle" :disabled="strategy.dynamic_stake"></b-input>-->
<!--                    </b-field>-->
<!--                  </div>-->
<!--                  <div class="column is-2">-->
<!--                    <b-field :label="$ml.get('accounts_stake') + ' 3'" class="plate"-->
<!--                             :message="'strategies' in errors && ix in errors['strategies'] && 'stake3' in errors['strategies'][ix] ? errors['strategies'][ix]['stake3'] : ''"-->
<!--                             :type="'strategies' in errors && ix in errors['strategies'] && 'stake3' in errors['strategies'][ix] ? 'is-danger' : ''">-->
<!--                      <b-input v-model="strategy.stake3" icon="currency-usd-circle" :disabled="strategy.dynamic_stake"></b-input>-->
<!--                    </b-field>-->
<!--                  </div>-->
                  <div class="column is-1">
                    <b-button size="is-small" type="is-danger" outlined
                              @click="removeStrategy(ix)" :title="$ml.get('common_delete')">
                      <b-icon icon="delete" size="is-small"></b-icon>
                    </b-button>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-2">
                    <b-field
                        :label="$ml.get('accounts_max_bets') + ' *'" class="plate"
                        :message="'strategies' in errors && ix in errors['strategies'] && 'bets_per_event' in errors['strategies'][ix] ? errors['strategies'][ix]['bets_per_event'] : $ml.get('accounts_max_bets_placeholder')"
                        :type="'strategies' in errors && ix in errors['strategies'] && 'bets_per_event' in errors['strategies'][ix] ? 'is-danger' : ''">
                      <b-input v-model="strategy.bets_per_event" icon="cog"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-2">
                    <b-field
                        :label="$ml.get('accounts_bet_interval') + ' *'" class="plate"
                        :message="'strategies' in errors && ix in errors['strategies'] && 'bets_interval' in errors['strategies'][ix] ? errors['strategies'][ix]['bets_interval'] : $ml.get('accounts_bet_interval_placeholder')"
                        :type="'strategies' in errors && ix in errors['strategies'] && 'bets_interval' in errors['strategies'][ix] ? 'is-danger' : ''">
                      <b-input v-model="strategy.bets_interval" icon="cog"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-1"></div>
                  <div class="column is-4">
                    <b-field>
                      <b-switch style="padding-top: 45px" v-model="strategy.dynamic_stake">
                        {{ $ml.get('accounts_dynamic_stake') }}
                      </b-switch>
                    </b-field>
                  </div>
                  <div class="column is-2">
                    <b-field :label="$ml.get('accounts_balance_percent') + ' *'" class="plate"
                             :message="'strategies' in errors && ix in errors['strategies'] && 'dynamic_stake_percent' in errors['strategies'][ix] ? errors['strategies'][ix]['dynamic_stake_percent'] : ''"
                             :type="'strategies' in errors && ix in errors['strategies'] && 'dynamic_stake_percent' in errors['strategies'][ix] ? 'is-danger' : ''">
                      <b-input v-model="strategy.dynamic_stake_percent" icon="percent" :disabled="!strategy.dynamic_stake"></b-input>
                    </b-field>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column is-12 leftal">
                  <b-button :disabled="form.strategies.length >= 5"
                            @click="addStrategy()"
                            type="is-success">
                    <b-icon icon="plus" size="is-small"></b-icon>
                    <span>{{ $ml.get('accounts_add_strategy') }}</span>
                  </b-button>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item value="schedule" :label="$ml.get('accounts_schedule')" icon="calendar-clock">
              <div class="columns is-centered">
                <div class="column is-8">
                  <b-message
                      title=""
                      type="is-info"
                      has-icon
                      aria-close-label="Close message">
                    {{ $ml.get('accounts_schedule_info') }}
                  </b-message>
                </div>
              </div>
              <div class="columns is-centered">
                <div class="column is-4" style="padding-top: 20px">
                  <b-field>
                    <b-switch v-model="form.manual_timetable">
                      {{ $ml.get('accounts_timetable_manual') }}
                    </b-switch>
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-button type="is-warning" outlined @click="resetTimetable()">{{ $ml.get('accounts_timetable_reset') }}</b-button>
                </div>
              </div>
              <div class="columns is-centered" v-if="form.manual_timetable">
                <div class="column is-8">
                  <b-message
                      title=""
                      type="is-warning"
                      has-icon
                      aria-close-label="Close message">
                    {{ $ml.get('accounts_timetable_manual_info') }}
                  </b-message>
                </div>
              </div>
              <div class="columns is-centered">
                <div class="column is-8">
                  <table class="table is-bordered schedule-table">
                    <thead>
                    <tr>
                      <th></th>
                      <th class="text-center">{{ $ml.get('common_monday') }}</th>
                      <th class="text-center">{{ $ml.get('common_tuesday') }}</th>
                      <th class="text-center">{{ $ml.get('common_wednesday') }}</th>
                      <th class="text-center">{{ $ml.get('common_thursday') }}</th>
                      <th class="text-center">{{ $ml.get('common_friday') }}</th>
                      <th class="text-center">{{ $ml.get('common_saturday') }}</th>
                      <th class="text-center">{{ $ml.get('common_sunday') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="hour in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]">
                      <td class="rightal">{{ hour - 1 }}:00 - {{ hour }}:00</td>
                      <td v-for="day in [1,2,3,4,5,6,7]"
                          :class="'bot-schedule ' + (form.timetable[day][hour] ? 'enabled' : 'disabled')"
                          @click="switchSchedule(day, hour)">
                        <b-icon :icon="form.timetable[day][hour] ? 'check' : 'cancel'" size="is-small"></b-icon>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <b-button :disabled="!isValid" type="is-info" native-type="submit">{{ $ml.get('common_save') }}</b-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue";
import router from "@/router";
import {mapGetters} from 'vuex'
import Multiselect from "vue-multiselect";
import Sport from "@/components/Sport";

export default {
  name: "Create",
  data() {
    return {
      form: {
        id: null,
        name: '',
        currency: '',
        login: '',
        bookmaker: '',
        bk_mirror: '',
        password: '',
        proxy_id: null,
        comment: '',
        balance_deposit_notification: null,
        balance_withdraw_notification: null,
        manual_timetable: 0,
        leagues_include: 0,
        settings: {},
        leagues: [],
        strategies: [
          {
            strategy_id: '',
            stake1: '',
            stake2: '',
            stake3: '',
            dynamic_stake: false,
            dynamic_stake_percent: null,
            bets_per_event: 1,
            bets_interval: 120,
          }
        ],
        timetable: {
          1: {
            1: true,
            2: true,
            3: true,
            4: true,
            5: true,
            6: true,
            7: true,
            8: true,
            9: true,
            10: true,
            11: true,
            12: true,
            13: true,
            14: true,
            15: true,
            16: true,
            17: true,
            18: true,
            19: true,
            20: true,
            21: true,
            22: true,
            23: true,
            24: true,
          },
          2: {
            1: true,
            2: true,
            3: true,
            4: true,
            5: true,
            6: true,
            7: true,
            8: true,
            9: true,
            10: true,
            11: true,
            12: true,
            13: true,
            14: true,
            15: true,
            16: true,
            17: true,
            18: true,
            19: true,
            20: true,
            21: true,
            22: true,
            23: true,
            24: true,
          },
          3: {
            1: true,
            2: true,
            3: true,
            4: true,
            5: true,
            6: true,
            7: true,
            8: true,
            9: true,
            10: true,
            11: true,
            12: true,
            13: true,
            14: true,
            15: true,
            16: true,
            17: true,
            18: true,
            19: true,
            20: true,
            21: true,
            22: true,
            23: true,
            24: true,
          },
          4: {
            1: true,
            2: true,
            3: true,
            4: true,
            5: true,
            6: true,
            7: true,
            8: true,
            9: true,
            10: true,
            11: true,
            12: true,
            13: true,
            14: true,
            15: true,
            16: true,
            17: true,
            18: true,
            19: true,
            20: true,
            21: true,
            22: true,
            23: true,
            24: true,
          },
          5: {
            1: true,
            2: true,
            3: true,
            4: true,
            5: true,
            6: true,
            7: true,
            8: true,
            9: true,
            10: true,
            11: true,
            12: true,
            13: true,
            14: true,
            15: true,
            16: true,
            17: true,
            18: true,
            19: true,
            20: true,
            21: true,
            22: true,
            23: true,
            24: true,
          },
          6: {
            1: true,
            2: true,
            3: true,
            4: true,
            5: true,
            6: true,
            7: true,
            8: true,
            9: true,
            10: true,
            11: true,
            12: true,
            13: true,
            14: true,
            15: true,
            16: true,
            17: true,
            18: true,
            19: true,
            20: true,
            21: true,
            22: true,
            23: true,
            24: true,
          },
          7: {
            1: true,
            2: true,
            3: true,
            4: true,
            5: true,
            6: true,
            7: true,
            8: true,
            9: true,
            10: true,
            11: true,
            12: true,
            13: true,
            14: true,
            15: true,
            16: true,
            17: true,
            18: true,
            19: true,
            20: true,
            21: true,
            22: true,
            23: true,
            24: true,
          },
        },
      },
      loading: false,
      timetable_loading: false,
      load_settings: null,
      errors: {},
      search_league: '',
      leagues_list: [],
    }
  },
  components: {
    Multiselect, Sport
  },
  computed: {
    ...mapGetters('data', ['strategies', 'bookmakers', 'currencies', 'sports', 'markets', 'bk_mirrors']),
    active_tab: {
      get() {
        let def = 'data'
        if ('strategies' in this.errors) {
          def = 'strategies'
        } else if (
            'balance_deposit_notification' in this.errors || 'balance_withdraw_notification' in this.errors ||
            'bets_per_event' in this.errors || 'bets_interval' in this.errors || 'sports' in this.errors
        ) {
          def = 'settings'
        }
        return def
      },
      set() {
      }
    },
    sport_tab: {
      get() {
        return undefined
      },
      set () {
      }
    },
    isValid() {
      let valid = (this.form.name.length > 0 && this.form.login.length > 0 && this.form.password.length > 0 &&
          this.form.bookmaker.length > 0 && this.form.currency.length > 0 && this.form.strategies.length > 0)

      return valid
    },
    proxies() {
      return (this.form.bookmaker.includes('BET365')) ? this.$store.getters['data/mobileProxies'] : this.$store.getters['data/proxies']
    },
    rooms() {
      return this.$store.getters['data/myRooms']
    },
    mirrors() {
      if (this.form.bookmaker in this.bk_mirrors) {
        return this.bk_mirrors[this.form.bookmaker]
      }
      return [];
    }
  },
  watch: {
    search_league: function(val, oldVal) {
      if (val !== oldVal && val.length >= 3) {
        Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `rooms/get_leagues?q=${val}`).then((resp) => {
          this.leagues_list = resp.data.result
        })
      }
    }
  },
  methods: {
    addLeague: function(league) {
      if (!this.form.leagues.includes(league)) {
        this.form.leagues.push(league)
      }
    },
    removeLeague: function(league) {
      this.form.leagues = this.form.leagues.filter(item => item !== league)
    },
    loadSettings: function() {
      this.loading = true
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `rooms/get_settings?room_id=${this.load_settings}`).then((resp) => {
        Object.keys(resp.data.result).forEach(key => {
          this.form[key] = resp.data.result[key]
        });
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
    },
    switchSchedule: function (day, hour) {
      if (this.form.manual_timetable) {
        this.form.timetable[day][hour] = !this.form.timetable[day][hour]
      }
    },
    addStrategy: function () {
      this.form.strategies.push({
        strategy_id: '',
        stake1: '',
        stake2: '',
        stake3: '',
        dynamic_stake: false,
        dynamic_stake_percent: null,
        bets_per_event: 1,
        bets_interval: 120,
      })
    },
    removeStrategy: function (i) {
      this.form.strategies.splice(i, 1)
      this.updateTimetable(0)
    },
    resetTimetable: function() {
      if (confirm(this.$ml.get('common_confirm'))) {
        this.updateTimetable(1)
      }
    },
    updateTimetable: function (force) {
      if (force === 1 || !this.form.manual_timetable) {
        this.timetable_loading = true
        let ids = [];
        this.form.strategies.forEach((item, i, arr) => {
          ids.push(item.strategy_id)
        })
        Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `rooms/build_timetable?s=${ids.join(',')}`).then((resp) => {
          this.form.timetable = resp.data.result
        }).catch(err => {
          this.flashMessage.error({
            title: this.$ml.get('common_request_failed'),
            message: this.$ml.get('common_request_failed_message'),
          })
        }).finally(() => {
          this.timetable_loading = false
        })
      }
    },
    submit: function () {
      this.loading = true
      this.errors = {}
      Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + 'rooms/index', {room: this.form}).then(resp => {
        this.flashMessage.success({
          title: this.$ml.get('common_request_success'),
          message: this.$ml.get('common_request_success_data_message'),
        })
        if (!this.form.id) {
          router.push('/rooms')
        }
      }).catch(err => {
        if (err.response.status === 400) {
          this.errors = err.response.data.result
        } else {
          this.flashMessage.error({
            title: this.$ml.get('common_request_failed'),
            message: this.$ml.get('common_request_failed_message'),
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
  },
  beforeMount() {
    this.$store.dispatch('data/strategies')
    this.$store.dispatch('data/proxies')
    this.$store.dispatch('data/myRooms')
    this.sports.forEach((sport) => {
      this.form.settings[sport] = {
        active: true,
        coef_from: 1.5,
        coef_to: 2.4,
        full_time_only: false,
        not_bet_junior: false,
        not_bet_woman: false,
        markets: {
          ONE_TWO: {
            ONE: {
              active: true,
            },
            TWO: {
              active: true,
            },
            ONE_TWO: {
              active: true,
            },
            DRAW: {
              active: true,
            },
            ONE_DRAW: {
              active: true,
            },
            TWO_DRAW: {
              active: true,
            },
          },
          TOTAL: {
            UNDER: {
              active: true,
              pivot_from: null,
              pivot_to: null,
            },
            OVER: {
              active: true,
              pivot_from: null,
              pivot_to: null,
            },
          },
          T1_TOTAL: {
            UNDER: {
              active: true,
              pivot_from: null,
              pivot_to: null,
            },
            OVER: {
              active: true,
              pivot_from: null,
              pivot_to: null,
            },
          },
          T2_TOTAL: {
            UNDER: {
              active: true,
              pivot_from: null,
              pivot_to: null,
            },
            OVER: {
              active: true,
              pivot_from: null,
              pivot_to: null,
            },
          },
          HDP: {
            HOME: {
              active: true,
              pivot_from: null,
              pivot_to: null,
            },
            AWAY: {
              active: true,
              pivot_from: null,
              pivot_to: null,
            },
          },
          EURO_HDP: {
            H1: {
              active: true,
              pivot_from: null,
              pivot_to: null,
            },
            H2: {
              active: true,
              pivot_from: null,
              pivot_to: null,
            },
            HX: {
              active: true,
              pivot_from: null,
              pivot_to: null,
            },
          },
        },
      };
      if (sport === 'FOOTBALL') {
        this.form.settings[sport]['markets']['CORNER_TOTAL'] = {
          UNDER: {
            active: true,
            pivot_from: null,
            pivot_to: null,
          },
          OVER: {
            active: true,
            pivot_from: null,
            pivot_to: null,
          },
        }
        this.form.settings[sport]['markets']['CORNER_HDP'] = {
          HOME: {
            active: true,
            pivot_from: null,
            pivot_to: null,
          },
          AWAY: {
            active: true,
            pivot_from: null,
            pivot_to: null,
          },
        }
      }
    })
    let id = this.$route.query.id
    if (id) {
      this.loading = true
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `rooms/index?id=${id}`).then((resp) => {
        this.form = resp.data.result
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
    }
  },
  metaInfo() {
    return {
      title: this.form.name ? this.form.name : this.$ml.get('titles_accounts_new_account')
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
table.schedule-table {
  table-layout: fixed;
  width: 100%;
}

table.schedule-table td, table.schedule-table th {
  padding: 3px;
}

table.schedule-table td.bot-schedule {
  cursor: pointer;
}

.bot-schedule.enabled {
  background-color: rgba(92, 184, 92, 0.7);
}

.bot-schedule.disabled {
  background-color: lightgray;
}
.market-cont {
  border: solid #8888;
  border-width: 0 0 0 1px;
}
.market-cont .market-name {
  padding: 5px;
  margin: -10px 0 0 -10px;
  border: 1px solid #8888;
  width: fit-content;
  background-color: white;
}
.market-cont .market-body {
  display: flex;
  align-items: center;
}
.market-cont .market-body .field {
  margin: 5px;
}
.markets-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.pivot-input {
  width: 70px;
}
.search-results {
  display: flex;
  justify-content: center;
}
.search-results ul {
  width: 100%;
  padding: 6px;
}
.search-results li {
  padding: 5px;
  border-bottom: 1px dotted;
  display: flex;
  align-items: center;
}
.search-results li:hover {
  background-color: rgba(221, 221, 221, .5);
  cursor: pointer;
}
.search-results li span {
  display: inline-block;
  margin-left: 20px;
}
</style>